<template>

 
  <ClientOnly>
    <Teleport to="#app">
      
      <UiModal
        v-if="activeContext"
        :title="''"
        :width="'800px'"
        :is-scroll-content="true"
        :is-auto-height="true"
        :max-width="'800px'"
        @close="close"         
        >        

        <div v-if="isLoading" class="gloading">          
        </div>

        <div v-else class="diary_info" v-if="wikiData">

          <div class="title_setup">
            <img class="title_img" v-if="wikiData.icon" :src="wikiData.icon" :title="wikiData.title">
            <div class="setup_info">
              <div class="title_name">
              {{ wikiData.title  }}
              </div>
              <div class="subtitle_name">
                {{ wikiData.sub_title  }}
              </div>
            </div>
          </div>
          

          <div class="setup_gallery" v-if="wikiData.cover && wikiData.gallery">
            <div class="setup_cover">
              <img :src="wikiData.cover" :title="wikiData.title">
            </div>
            <div class="setup_gallery_images">
              <a 
                v-for="(image) in wikiData.gallery"
                :href="image.url">
                <img :src="image.photo">
              </a>
            </div>
          </div>

          <div v-else class="empty_gallery">
            
          </div>


          <div class="setup_content" v-html="wikiData.info"></div>

          <div class="diaries_cnt" v-if="wikiData.link && wikiData.cnt_diaries" @click="close">
            <NuxtLink class="link" :to="wikiData.link">
              Show {{ wikiData.title }} diaries ({{ wikiData.cnt_diaries }})
            </NuxtLink>
          </div>

          <div class="see_more_setups" v-if="wikiData.similar">
            See more:
            <span 
              v-for="(sim) in wikiData.similar"
              class="item_setups"
              @click="openFull(sim.type, sim.name)">
              {{ sim.name }}
            </span>
          </div>


        </div>

      </UiModal>

    </Teleport>    

  </ClientOnly>

</template>

<script setup>
 
const { $api } = useNuxtApp();
 
// const props = defineProps({
  
// })

const activeContext = ref(useWikiModalFull().active);
const data = ref(useWikiModalFull().data);
const wikiData = ref(null);
const isLoading = ref(false);


const close = () => {
  useWikiModalFull().close();
  // activeContext.value = false;
}
const openFull = (type, id) => {
  close();
  useWikiModalFull().open(type, id);
}

const loadContent = async () => {
  isLoading.value = true;
  if(data.value.type && data.value.id)
  wikiData.value = await $api.getWiki('full', data.value.type, data.value.id);
  isLoading.value = false;
}



watch(() => data, (new_data) => {
  console.log('new_data', new_data);
    loadContent();
}, { deep: true })

</script>

<style scoped>
  .ctx{        
    min-width: 59px;
    display: flex;
    justify-content: center;
  }
  .ctx > i{    
    padding: 14px 20px 14px 25px;
    cursor: pointer;
  }

  /*  */
  


.diary_info .setup_info{
  text-align: left;
}
.diary_info .title_setup{
  position: absolute;left: 20px;top: 20px;background-color: #ffffff;border-radius: 5px;display: flex;align-items: center;padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.92);
  z-index: 1;
}
.diary_info .title_img{
    width: 45px;
    display: block;
    margin-right: 10px;
}

.diary_info .text_short{text-align: left;}

.diary_info .title_name{

  font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
}

.diary_info .subtitle_name{color: gray;text-transform: uppercase;}

.diary_info .setup_cover{
  width: 100%;
  height: auto;
}
.diary_info .setup_cover img{
  width: 100%;
  height: auto;
  aspect-ratio: 700/260;
  object-fit: cover;
}
.diary_info b{
  font-weight: bold;
}
.diary_info .setup_gallery{
  position: relative;
}
.diary_info .setup_gallery_images{
  position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
}
.diary_info .setup_gallery_images a{
      position: relative;
}
.diary_info .setup_gallery_images a img{
  width: 70px;
  margin-left: 10px;
  border-radius: 5px;
  border: 3px rgba(255, 255, 255, 0.62) solid;
}

.diary_info .setup_content{
  padding: 0px;
}

.diary_info .setup_links{
  margin-top: 20px;
}


.diary_info .setup_links > *{
  color: #4183C4;
  text-decoration: none;
  cursor: pointer;
  margin-right: 10px;
}
.diary_info .setup_links > *:hover {
  color: #1e70bf;
  text-decoration: none;
}


.diary_info .video_play{
  width: 28px;
  height: 24px;
  z-index: 1;
  position: absolute;
  background-image: url(/images/play_button.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 30px;
  left: calc(50% - 9px);
  top: calc(50% - 14px);
}

.diary_info .see_more_setups{
  padding: 1rem;
  color: gray;
  background-color: #f5f5f594;
  margin-top: -10px;
  margin-left: -1rem;
  width: calc(100% + 2rem);
  margin-bottom: -1rem;

  display: flex;
    flex-wrap: wrap;
    gap: 0.3rem 0.5rem;
}
.diary_info .see_more_setups .item_setups{
  color: #4183C4;
  text-decoration: none;
  cursor: pointer;
    text-transform: capitalize;
}
.diary_info .see_more_setups .item_setups:hover {
  color: #1e70bf;
  text-decoration: none;
}

.diary_info .diaries_cnt{

  margin-top: 1rem;
  margin-bottom: 1rem;
}

.diary_info .diaries_cnt .link{
    color: #4183C4;
}

.diary_info .diaries_cnt .link:hover{
  color: #1e70bf!important;
}

.empty_gallery{
  height: 70px;
}
.gloading{
  min-height: 100px;
}

@media only screen and (max-width: 767px) {


  .diary_info .setup_gallery_images {
    position: relative;
    top: 0;
    right: 0;
    display: flex;
  }

  .diary_info.data > .content{
        height: 100%!important;
  }

  .diary_info .title_setup {
    position: relative; 
    top:0;
    left: 0;
    padding: 0;
    margin-bottom: 1rem;
  }

  .diary_info .setup_cover {
    width: 100%;
    height: auto;
  }

  .diary_info .setup_cover img {
    width: calc(100% - 20px);
    margin-left: 10px;
  }

  .diary_info .setup_gallery_images {
    position: relative;
    top: 0;
    right: 0;
    display: block;
    justify-content: center;
    width: calc(100% - 17px);
    overflow: auto;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-align: center;
    margin: 3px 8px 0px 8px;
  }
  .diary_info .setup_gallery_images a {
    position: relative;
    display: inline-block;
    height: 60px;
    width: 110px;
    overflow: hidden;
    border-radius: 3px;
  }
  .diary_info .setup_gallery_images a img {
    width: 100%;
    margin-left: 0px;
    border-radius: 5px;
    border: 3px rgba(255, 255, 255, 0.62) solid;
    margin-top: -22px;
  }
  .diary_info .setup_content {

  }

  .empty_gallery{
    display: none;
  }

}


</style>
