<template>
  <div 
  :class="{
    'bd': true, 
    'pg': true, 
    'onnav': navbar, 
    'offnav': !navbar, 
    'scrolled': isScrolled
    }"    
  :theme="useNavbarThemeRequired().value || useNavbarTheme().value"
  :data-section="getSection"
  :data-os="detectedOs"
  ref="app"
  id="app"
  >  
  
    <WarningMigrate v-if="useWarningMigrate().value && !useWarningMigrateHidden().value" />

    <NuxtLoadingIndicator
      :color="'#008957'"
      :height="2"
      :throttle="0"
      />

    <div class="observer"></div>
      
    
    <Debug />      

    <Popup />
    
    <Topbar />    

    <Navbar />    

    <ChatWidget v-show="$route.currentRoute.value.path.indexOf('/shorts') < 0"/>    

    <UiScrollTop 
      />

    <ModalsAgree 
      v-if="!useCookie('general_agree').value && !useAuth().isAuth()"
      />

    <JoinEmailPopup 
      v-if="!useAuth().isAuth()"
      />
 

    <div class="pc"> 
      <AdvHeaderComp/>
      <NuxtPage />
    </div>



    <ModalsDiary
      v-if="useModalsDiaryShow().value"
      />
  


      <div id="teleport"></div>
     
  </div> 


  <GeneralLightboxDiary
    v-if="useLightboxDiary().lightboxDiary"
    />
    
  <ModalsWiki
    v-if="useWikiModal().active"
    />
    
  <ModalsWikiFull
    v-if="useWikiModalFull().active"
    />

 



</template>



<script setup>

const { $i18n } = useNuxtApp();

const $route = useRouter()
const chat = useChatOpened();
const navbar = useNavbarOpened();
// const navbar_theme = useNavbarTheme();
const detectedOs = ref(null);

const isScrolled = ref(false);
const observer = ref(null);

useHead({ 
  htmlAttrs: {
    lang: $i18n.locale
  }
})

const getSection = computed(() => {
var section = $route.currentRoute.value.path.split('/')[1];
  return section ? section : 'home';
});

const scrollHandler = (entries) => {
  isScrolled.value = !entries[0].isIntersecting;
};

const detectOs = () => {
  if(import.meta.client){
    var OSName = "none";
    if (navigator.appVersion.indexOf("Win") != -1) OSName = "windows";
    if (navigator.appVersion.indexOf("Mac") != -1) OSName = "mac";
    if (navigator.appVersion.indexOf("X11") != -1) OSName = "unix";
    if (navigator.appVersion.indexOf("Linux") != -1) OSName = "linux";
    detectedOs.value = OSName;
  }
}

onMounted(() => {

  if (import.meta.client && window) {
    window.history.scrollRestoration = 'auto';
  }


  detectOs();

  if(import.meta.client){
    if(window.outerWidth < 768)
      navbar.value = useCookie('navbar-opened',{maxAge: 60 * 60 * 24 * 31 * 6}).value = false;
  }

  observer.value = new IntersectionObserver(scrollHandler, {
    root: null,
    threshold: 0.1
  });

  const header = document.querySelector('.observer');
  if (header) observer.value.observe(header);
});

onUnmounted(() => {
  if (observer.value) observer.value.disconnect();
});


</script>
 


<style>
.pg{
  display: flex;    
  align-items: flex-start;
  flex-direction: column;
  min-height: 100%;
}
.pg > nav{
  flex-shrink: 0;
  width: 100%;
}
.pg > .pc{
  width: calc(100% - 65px);   
  padding: 0 0rem;
  margin-left: 65px;
  min-height: 100%;
  flex-direction: column;
  container: pc / inline-size;
}

.pg.onnav > .pc{
  width: calc(100% - 235px);
  margin-left: 235px;
}


@media (max-width: 768px) {
  .pg > .pc{
    margin-left: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
    .pg.onnav > .pc{
    width: 100%;
    margin-left: 0px;
    }
}

/*  */



/* PB */

.pg,
.pg > .pc{
  min-height: 100%;
}
.pg .pc .pb{
  /* max-width: 1620px; */
  max-width: 1320px;
  margin: 0px auto;
  width: calc(100% - 90px);
  padding-top: 1rem;
  padding-bottom: 1rem;
  container: pb / inline-size;
}
.pg .pc .pb.tabs_container{
  padding-top: 20px;
  padding-bottom: 0px;
}
.pg .pc .pb.grid_width{
  width: calc(100% - 20px);
}

.pg .pc .pb.limited{
  max-width: 1320px;
}
@container pc (max-width: 500px) {
  .pg .pc .pb{
    width: calc(100% - 20px);
  }
}
@container pc (min-width: 650px) {
  .pg .pc .pb.grid_width{
    max-width: 556px;
  }
}

@container pc (min-width: 800px) {
  .pg .pc .pb.grid_width{
    max-width: 756px;
  }
}
@container pc (min-width: 1000px) {
  .pg .pc .pb.grid_width{
    max-width: 956px;
  }
}

@container pc (min-width: 1200px) {
  .pg .pc .pb.grid_width{
    max-width: 1156px;
  }
}

@container pc (min-width: 1400px) {
  .pg .pc .pb.grid_width:not(.limited){
    max-width: 1356px;
  }
}

@container pc (min-width: 1600px) {
  .pg .pc .pb.grid_width:not(.limited){
    max-width: 1556px;
  }
}
 

@container pc (max-width: 500px) {
  .pg .pc .pb.grid_width,
  .pg .pc .pb{
    width: calc(100% - 40px);
  }
}



</style>
