<template>
  <div>

    <div class="ttl skl"></div>

    <div 
      v-for="(o, k) in [0,1,2,3,4,5,6,7,8,9,10]"
      :key="k"
      class="it">
      <div class="av skl"></div>
      <div class="nm skl"></div>
    </div>
 
 
  </div>
</template>


<script>
 
export default {
  components: {    
 
  },
  data() {
    return {
 
    };
  },
  methods: { 
 
  }
}
</script>
  

<style scoped>
  
.ttl{
  margin: 0px 30px;
  width: 100px;
  height: 22px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.it{
  display: flex;  
  justify-content: flex-start;
  margin: 0px 30px;
  margin-bottom: 10px;
  height: 20px; 
}
.it .av{
  width: 22px;  
  margin-right: 10px;
  flex-shrink: 0;
}
.it .nm{
  width: 100px;  
}



</style>
