<template>

  <div :class="{'header_add': true, 'hidden': isClose}" v-if="data && data.id">


      <div class="adv-visibility" ref="elementObserver"></div>


      <a target="_black" @click="click" rel="nofollow" :href="data.redirect" class="over"></a>

      <a target="_black" @click="click" rel="nofollow" :href="data.redirect_mob" class="over_mob"></a>

      <div class="page_content grid_width">
        <div class="backg_far">
          <img data-not-lazy :src.attr="data.s_back" class="bg" alt="Recommended">
          <img data-not-lazy :src.attr="data.s_mob_back" class="bgm" alt="Recommended">
          <div class="close_btn" @click.prevent.stop="close"><i class="icon-remove"></i></div>
        </div>
      </div>

  </div>

</template>


<script setup>

const router = useRouter();
const { $observer } = useNuxtApp()

const data = ref(advHeader.getHeaderBanner());
const viewed = ref(false);
const elementObserver = ref(null);


const isClose = ref(false)
const appear = (res) => {
  if (!viewed.value && res && data.value?.id){
    advHeader.viewHeader(data.value.id)
  }
  viewed.value = true;
}

const click = () => {
  advHeader.clickHeader(data.value.id)
  // console.log('clicked');
}

const close = () => {
  advHeader.addBannerHidden('header', data.value.id)
  data.value = null;
}



watch(() => router.currentRoute.value, async (to, from) => {
  // console.log('route changed');
  data.value = advHeader.getHeaderBanner();
  viewed.value = false;
  appear(true);
});

onMounted(() => {
  $observer(elementObserver.value, (element) => {
    appear(true);
  });
});

</script>
  

<style scoped>
.header_add {
  position: relative;
  margin-bottom: 15px;
  margin: 0 auto;
  display: flex;
  max-width: 1600px!important;
  width: calc(100% - 90px);
  margin-top: 1rem;
}

.header_add .page_content {
  display: flex;
  max-width: 1600px !important;
}

.header_add .over {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;

}

.header_add .over_mob {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;

}

.header_add .info {
  position: relative;
  z-index: 1;
  /* margin-top: 60px; */
  width: 100%;
  max-width: 455px;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.header_add .info .brand {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.header_add .info .brand .logo {
  max-height: 70px;
  margin-right: 0.5rem;
  max-width: 110px;
}

.header_add .info .brand .name {
  font-size: 1.4rem;
  font-weight: bold;
  font-family: sans-serif;
  color: black;
}

.header_add .info .title {
  font-size: 2.3rem;
  font-weight: bold;
  font-family: sans-serif;
  color: black;
  line-height: 2.5rem;
}

.header_add .info .btn {
  padding: 0.4rem 2rem;
  border: 2px black solid;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: bold;
  font-family: sans-serif;
  margin-top: 1rem;
  display: inline-block;
  color: black;
}

.header_add .backg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: bottom;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}

.header_add .backg .bg {
  max-width: inherit;
  margin-left: 20%;
}

.header_add .backg_far {
  position: relative;
  /* width: 100%; */
}

.header_add .backg_far .bg {
  width: 100%;
  border-radius: 15px;
  display: block;
  aspect-ratio: 2700/700;
}

.header_add .backg_far .bgm {
  display: none;
  aspect-ratio: 800/800;
  object-fit: cover;
}

.header_add .close_btn {
  z-index: 2;
  background-color: white;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: center;
  opacity: 0.5;
  font-size: 1.3rem;
  border-radius: 33px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  line-height: 18px;
  justify-content: center;
}

.header_add .close_btn:after{
  content: '';
  display: block;
  width: 160%;
  height: 160%;
  border-radius: 50%;
  position: absolute;
}
.header_add .close_btn:hover,
.header_add .close_btn:focus {
  opacity: 1;
}

.header_add .close_btn .icon-remove {
  margin: 0;
  font-size: 0.8rem;
}

/* COLORS */

.header_add.white .info .brand .name,
.header_add.white .info .btn,
.header_add.white .info .title {
  color: white;
}

.header_add.white .info .btn {
  border: 2px white solid;
  background-color: #00000087;
}

/* * */






.header_add .backg .bg {
  width: 1400px;
}



/* @media only screen and (max-width: 468px) {
  .header_add .backg_far .bgm {
    width: 100%;
    border-radius: 15px;
  }

  .header_add .backg_far {
    position: relative;
    margin: 2rem 2rem 0 2rem;
  }

  .header_add .over_mob {
    display: block;
  }

  .header_add .over {
    display: none;
  }
} */

@media only screen and (max-width: 768px) {
  .bd[data-section="home"] .header_add {
    display: none;
  }


  .header_add .backg_far .bg {
    border-radius: 0px;
  }
}

@media only screen and (max-width: 468px) {
  .header_add .backg .bg {
    margin-left: 40%;
  }

  .header_add .backg_far .bg {
    display: none;
  }

  .header_add .backg_far .bgm {
    display: block;
    width: 100%;
  }

  .header_add .over_mob {
    display: block;
  }

  .header_add .over {
    display: none;
  }

  .header_add .page_content {
    width: auto !important;
    padding-top: 0 !important;
  }

  .header_add {
    width: 100%;
    margin-top: 0;
  }

  .header_add .info .title {
    text-align: center;
  }

  .header_add .info {

    max-width: 100%;
    align-items: center;
    margin-left: 0;
    justify-content: flex-start;
    align-items: center;

  }

  .header_add .backg .bg {
    margin-left: -10%;
    width: 1300px;
    /* width: auto; */
  } 

  .header_add .backg_far .bgm {
    width: 100%;
    border-radius: 0px;
  }

  .header_add .backg_far {
    position: relative;
    margin: 0;
  }

  .header_add .over_mob {
    display: block;
  }

  .header_add .over {
    display: none;
  }

}

</style>
