<template>
  <div class="warn">
    Website Updated 🚀 Experiencing issues? <wbr /> Tell us - <a href="/contact">feedback form</a> or <a href="mailto:info@growdiaries.com">email</a>, we're here!
  </div>
</template>


<script setup>
 

</script>

  

<style scoped>
 .warn{
  width: 100%;
  text-align: center;
  background-color: #FDFD96;
  font-weight: bold;
  font-size: 0.7rem;
  padding: 4px 34px;
  color: #404040;
  position: relative;
  z-index: 102;
 }
 .warn a{
  color: #000;
  text-decoration: underline;
 }

</style>
