<template>

  <UiModal
    v-if="active"
    :width="'350px'"
    title=""
    :is-auto-height="true"
    :is-rounded="true"
    :is-mobile-margin="true"
    @close="close"
    >

    <div class="imb">
      <img src="/images/signup_pp_users.jpg" class="im" />
      <div class="hint">Sign up now to get freebies from GrowDiaries' sponsors</div>
      <div class="ui input imp">
        <input id="signup_pp_mail" v-model="email" type="text" placeholder="E-mail">
      </div>

      <UiButton
        :name="'Send me freebies'"
        :type="'primary wide'"
        @click="join"
        :is-loading="isLoding"
        />      

      <UiButton
        :name="'No, thanks'"
        :tag="'NuxtLink'"
        :type="'link wide'"
        @click="close"
        />   

    </div>

  </UiModal>

</template>

<script setup>

const router = useRouter();
const { $api, $popup } = useNuxtApp();
const { t } = useI18n();

const alreadyShowed = useCookie('signup_pp_show',{maxAge: 60 * 60 * 24 * 31 * 6});
const active = ref(false);
const email = ref('');
const notAllowedPaths = ref([
  '/video',
  '/external',
  '/auth/signin',
  '/auth/signup'
]);


const randomShowSeconds = (start, end) => {
  return Math.floor(Math.random() * (end - start + 1)) + start;
}


const validateEmail = (val) => {
  const re = /\S+@\S+\.\S+/
  return re.test(val)
}

const join = () => {
 
  if (!validateEmail(email.value)) {
    $popup.error('Invalid email address')
    return
  }

  router.push({
    path: '/auth/signup',
    query: {
      email: email.value
    }
  })
}
      

const isAllowShow = () => {

  if(import.meta.server){
    return false;
  }

  if(alreadyShowed.value){
    return false;
  }

  const path = router.currentRoute.value.path;
  const isAllowed = notAllowedPaths.value.filter((item) => {
    return path.indexOf(item) !== -1;
  });

  return isAllowed.length === 0;

}

const open = () => {
  alreadyShowed.value = true;
  active.value = true;
}

const close = () => {
  active.value = false;
}

onMounted(() => {
  setTimeout(() => {
    if(isAllowShow()){
      open();
    }
  }, randomShowSeconds(10, 30) * 1000);
})

 
</script>

<style scoped>


#uimodal_signup_pp .data{
  border-radius: 1rem;
  padding-top:2rem;
  padding-bottom:2rem;
}

#uimodal_signup_pp .actions,
#uimodal_signup_pp .title{
  display:none;
}

@media only screen and (max-width: 768px){
#uimodal_signup_pp .data {
  height: auto;
} 
}




.imb{display: flex;flex-direction: column;align-items: center;}
.imb .im{
  width: 100%;
  max-width: 230px;
}
.imb .hint{
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center!important;
}
.imb .imp{
  width: 100%;
  margin-bottom: 1rem;
}
.imb .imp input{
  text-align:center;
  padding: 8px;
  font-size: 0.92857143rem!important;
}
.imb .btn_signup{
  width: 90%;
}
.imb .btn_close{
  color: #c1c1c1;
  text-decoration: underline;
  margin-top: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 468px){
  .imb .im {
    max-width: 200px;
  } 
}

</style>
