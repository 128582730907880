<template>
  <div :class="{'topbar': true, 'is-hidden-mobile': useTopbarHiddenMobile().value}">
     
    <i class="icon-sidebar sdbr ic" @click.stop="toggleNavbar"></i>

    <TopbarLogo />    
 
    <TopbarSearch 
      :placeholder="$t('main_menu_search_diaries_placeholder')"
      />    
 
      <div class="rmenu">
  
        <NuxtLink v-if="!useAuth().isAuth()" class="lnk icon" to="/diaries/edit/new">                
          <UiButton type="outline">{{ $t('main_menu_start_diary') }}</UiButton>        
        </NuxtLink>
          
        <NuxtLink v-if="!useAuth().isAuth()"  class="lnk icon" to="/auth/signin">        
          <i class="icon-lock ic"></i> 
          {{ $t('main_menu_login') }}      
        </NuxtLink>      
        
        <NuxtLink v-if="!useAuth().isAuth()" class="lnk icon" to="/auth/signup">                
          <i class="icon-user-new ic"></i> 
          {{ $t('main_menu_signup') }}
        </NuxtLink>
        
      
        <div class="lnk icon diaries" v-if="useAuth().isAuth()" v-click-outside="hideUserPopupDiariesShow" @click="toggleUserPopupDiariesShow()">
          <i class="icon-plus ic"></i>      
          <UserPopupDiaries 
            v-if="showedUserPopupDiaries"   
            :showed="showedUserPopupDiaries" 
            @update:showed="hideUserPopupDiariesShow" 
            />
        </div>
      
        <div :class="'lnk icon notices ' + (useUserUpdatesLoading().value ? 'floading' : '')" v-if="useAuth().isAuth()" v-click-outside="hideUserPopupNoticesShow" @click="toggleUserPopupNoticesShow()">
          <div v-if="useUserUpdatesNotifications().value" class="label-new">
            {{ useUserUpdatesNotifications().value }}
          </div>
          <i class="icon-alarm ic"></i>      
          <UserPopupNotices 
            v-if="showedUserPopupNotices"   
            :showed="showedUserPopupNotices" 
            @update:showed="hideUserPopupNoticesShow" 
            />
        </div>
  
        <NuxtLink v-if="useAuth().isAuth() && useAuth().getLink()" class="lnk user" :title="useAuth().getName()" :to="useAuth().getLink()">       
          <img class="avatar" :src="useAuth().getAvatarLittle()">             
        </NuxtLink>  
  
        <div v-if="useAuth().isAuth()" class="lnk icon logout" @click="useAuth().logout({redirect: true})">      
          <i class="icon-logout ic"></i>
        </div>    

      </div>


 

    <div class="context">
      
      <div id="context" class="ctxb">
        
      </div>


      <div class="def">
        <NuxtLink v-if="useAuth().isAuth() && useAuth().getLink()" class="lnk user" :title="useAuth().getName()" :to="useAuth().getLink()">       
          <img class="avatar" :src="useAuth().getAvatarLittle()">             
        </NuxtLink>  
        <NuxtLink v-else class="lnk user" :title="useAuth().getName()"  to="/auth/signin">       
          {{ $t('main_menu_login') }}           
        </NuxtLink>  
      </div>

    </div>

  </div>
</template>
 
<script setup>

import { ref } from 'vue'

const { $api } = useNuxtApp();

const showedUserPopupDiaries = ref(false)
const showedUserPopupNotices = ref(false)
const navbarOpened = useNavbarOpened();
const followCommon = ref(false)
const followBookmark = ref(false)
const activeContext = ref(false)
const timerNotificationsCheck = ref(null)
const countNewNotifications = ref(0)
const status = ref(useAuth().status)

const hideUserPopupNoticesShow = function(){             
  showedUserPopupNotices.value = false;
}

const toggleUserPopupNoticesShow = function(){        
  showedUserPopupNotices.value = !showedUserPopupNotices.value;
}

const toggleUserPopupDiariesShow = function(){        
  showedUserPopupDiaries.value = !showedUserPopupDiaries.value;
}

const hideUserPopupDiariesShow = function(){             
  showedUserPopupDiaries.value = false;
}


const toggleNavbar = async function(){
  navbarOpened.value = !navbarOpened.value;
  useCookie('navbar-opened', {maxAge: 60 * 60 * 24 * 31 * 6}).value = navbarOpened.value;

  if(navbarOpened.value){
    // console.log(this.followCommon);
    if(!useFollowCommon().followCommon){
      await useFollowCommon().load()
      await useFollowBookmark().load()
    }        
  }
}


 





if(import.meta.client){
  followCommon.value = useFollowCommon().followCommon
  followBookmark.value = useFollowBookmark().followBookmark
}
 


</script>
  

<style scoped>
  

.topbar{
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 101;
  background-color: var(--gd-topbar-back-color);
  height: 52px;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 7%);
  display: flex;
  align-items: center; 
  justify-content: space-between;
}


.topbar .rmenu{
  margin-left: auto;
  display: flex;
  align-items: center;
    margin-right: 10px;

    flex-shrink: 0;
    margin-left: 1rem;
}

.topbar .sdbr{
  padding: 14px 20px 14px 25px;
  cursor: pointer;
}

.topbar .ic{
  font-size: 1.14rem;
  flex-shrink: 0;
  line-height: 1rem;
}

.label-new{
  position: absolute;
  top: 5px;
  right: 0px;
  background-color: var(--un-firm);
  color: white;
  font-size: 0.5rem;
  padding: 0px 5px;
  border-radius: 15px;
  min-width: 15px;
  font-weight: bold;
  line-height: 1rem;
  margin-right: 5px;
  pointer-events: none;
}

.notices{
  position: relative;
}
.diaries{
  position: relative;
}

.user{
  display: flex;
  align-items: center;
}

.user .avatar{
  border-radius: 40px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.lnk{
  cursor: pointer;
  padding: 15px 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  /* color: var(--gd-navbar-icon-color); */
}

.lnk.icon > i{

}
.topbar > .context{
  display: none;
}
.content{
  display: none;
}
@media (max-width: 768px) {
  .content{
    display: flex;
    align-items: center;
    width: 58px;
    justify-content: center;
  }

  .topbar > .context{
    display: block;
  }

  .context .ctxb:has(.ctx) + .def {
    display: none;
  }
  
  .topbar .rmenu{
    display: none;
  }

  .is-hidden-mobile{
    display: none;
  }

}


/*  */


</style>
