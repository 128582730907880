<template>            

  <div class="popup">

    <NuxtLink :to="'/diaries/edit/new'" class="row create">
      {{ $t('main_menu_start_diary') }}
    </NuxtLink>


    <div v-if="loading" class="bloading">
      <div class="gloading"></div>
    </div>

    <template v-if="diaries.length">

      <div class="row txt">
        {{ $t('main_menu_add_week') }}:
      </div>

      <template
        v-for="diary in diaries"
        >    
        <div @click="createWeek(diary)" class="row diary">
          <div class="av">
            <img :src="diary.avatar_little">
          </div>
          <div class="name">
            {{ diary.name }}
          </div>
        </div>
      </template>
    </template>
  </div>

  
</template>


<script setup>

const router = useRouter();
const { $api } = useNuxtApp();
const props = defineProps({
  showed: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['update:showed']);
const diaries = ref([]);
const loading = ref(false);
const start = ref(0);

const toggleLoader = (state) => {
  loading.value = state;
}

const loadUserDiaries = async function() {          
  // console.log('load diaries user');  
  loading.value = true;
  toggleLoader(true);
  const res = await $api.getDiaries({ 
      start: start.value,
      limit: 1000,
      owner: 1,
      tags: 'us:' + useAuth().getId(),
    })
  toggleLoader(false);
  diaries.value = res.items;
  loading.value = false;
  console.log('load diaries');        
}

const hidePopup = () => {
  emits('update:showed', false);
}

const createWeek = (diary) => {
  // this.$router.push({ name: 'foo', params: {title: 'test title' }})

  hidePopup();
  
  var link = diary.link.split('/');
  console.log('diary.link');
  console.log(diary.link);

  router.push({ 
    name: 'diaries-id-index',  
    params: { 
      id: link[2],
    }, 
    query: { 
      in_active_week_choose: 1,
    },

  })
}
 

onMounted(() => {
  loadUserDiaries();
})

onBeforeUnmount(() => {
  diaries.value = [];
})


</script>
  

<style scoped>
  
.popup{
  /* display: none; */
  position: absolute;
  right: 0;
  top: 50px;
  background-color: var(--gd-navbar-back-color);
  z-index: 10;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  min-width: 260px;
  max-height: 400px;
  overflow-y: auto;
  height: fit-content;
  cursor: default;
  
}
.popup.showed{
  display: flex;
  flex-direction: column;
}
.popup .row{
  padding: 0.58571429rem 1.14285714rem;
  display: flex;
  align-content: center;
  gap: 5px;
}

.popup .row.create{
  font-weight: bold;
  cursor: pointer;
}
.popup .row .name{
  cursor: pointer; 
  width: 100%;
}
.popup .row .av{
  width: 20px;
  height: 20px;
  border-radius: 5px;
  flex-shrink: 0;
}
.popup .row .av img{
  border-radius: 3px;
}
.popup .row i{
  
}

.popup .row:not(.txt):hover{
  background-color: var(  --gd-navbar-back-hover-color);
}

.bloading{
  position: relative;
  min-height: 45px;
}
@media (max-width: 768px) {
  
  .grdr{
    display: none;
  }
}

</style>
