<template>            

  <div :class="{'nt': true, 'is-new': isNew}">
    
    <NuxtLink 
      v-if="items_user.length > 0 && props.type != 'add_message'"
      class="user_img"
      :to="items_user[0].link"
      >
        <img :alt="items_user[0].name" :src="items_user[0].avatar_small">
        <!-- <div :class="'user_range u_range_' + items_user[0].status"></div> -->
    </NuxtLink>

    <div class="user_info more_users_link">


      <div class="item_table" v-if="props.type == 'add_message'">
        <div class="message_content" v-html="props.messageText"></div>
      </div>

      <NuxtLink 
        v-if="items_user.length > 0"        
        class="user_name"
        :to="items_user[0].link"
        >
        {{items_user[0].name}}
      </NuxtLink>
     
      <span class="moreusers" v-if="items_user.length > 1"  @click.stop.prevent="activeMoreUsers = true;"> +{{items_user.length - 1}} others</span>        
        

        <NuxtLink v-if="action" :to="link" class="act">
          {{ action ? $t(action) : '' }}
        </NuxtLink>

      <span class="dt">        
         {{ $dayjs.utc(last_update).fromNow() }}
      </span>
    </div>


    <NotificationMoreUsers
      v-if="items_user.length > 1"
      :list="items_user.slice(1,9)"
      :exists-more="items_user.length >= 10"
      :active="activeMoreUsers"
      :notification-id="props.notificationId"
      @close="activeMoreUsers = false"
      />

  </div>

  
</template>


<script setup>

const props = defineProps({
  last_update: {
    type: String,
    default: '',
  },
  notificationId: {
    type: Number,
    default: '',
  },
  link: {
    type: String,
    default: '',
  },
  action: {
    type: String,
    default: '',
  },
  items_user: {
    type: Array,
    default: [],
  },
  isNew: {
    type: Number,
    default: 0,
  },
  messageText: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
})

const activeMoreUsers = ref(false)

  
</script>
  

<style scoped>
  
.nt{
  padding: 6px 10px;
  display: flex;
  align-items: center;
}
.nt .user_img {
  width: 25px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  flex-shrink: 0;
}

.nt .user_img img {
  width: 100%;
  border-radius: 200px;
}

.nt .user_img .user_range {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 1.5rem;
  margin-right: 0px;
}
.nt .moreusers{
  cursor: pointer;
  font-weight: bold;
}
.nt .act {

  margin-right: 5px;
}
.nt .user_name {
  display: inline-block;
  color: #4183c4;
  margin-right: 5px;
}
.nt .message_content{
  padding: 5px;
}
.nt .message_content:deep(a){
  color:#4183C4;
  text-decoration: underline;
}
.nt .user_info {
  width: 100%;

}
.nt .user_action {
  display: inline-block;
  color: #494949;
}
.nt .comment_box {
  color: #494949;
}
.nt .dt{
  color: var(--gd-text-gray-color);
}

.nt.is-new {
  font-weight: bold;
  background-color: rgba(119, 175, 74, 0.12);
}

  
</style>
