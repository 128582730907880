<template>
   
    <NuxtLink :to="link" class="fit diary">
      <img v-if="avatar" :src="avatar" class="av">
      <span class="name" :title="name">{{ name }}</span>
      <span v-if="updates" class="update">{{ shortCounter(updates) }}</span>
    </NuxtLink>
  
</template>


<script>
 

export default {
  components: {        
  },
  props: {       
    avatar: {
      type: String
    },
    name: {
      type: String
    },
    link: {
      type: String
    },
    updates: {
      type: Number
    },
  },
  data() {
    return {
      // this.$store.state.user.common
    };
  },
  methods: { 
  }
}
</script>
  

<style scoped>
  
 
.fit:hover {
  background-color: var(--gd-navbar-back-hover-color);
}

.fit {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  position: relative;
  border-radius: 3px;
  width: 100%;
  gap: 10px;
}

.fit.active {
  font-weight: bold;
} 

.fit .av {
  width: 20px;
  height: 20px;
  max-width: inherit;
  flex-shrink: 0;
}
.fit.user .av {
  border-radius: 80px;
}
.fit.diary .av {
  border-radius: 3px;
}
.fit.active .av {
  box-shadow: 0px 0px 10px #67ae3c;
}

.fit .img {
  width: 20px;
  display: inline-block;
  height: 20px;
  border-radius: 20px;
}

.fit .name{
  width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fit.child {
  padding-left: 30px;
}

.fit.child::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  position: absolute;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #dedede;
  left: 13px;
}
.fit .update {
  display: inline-block;
  color: gray;
  right: 0px;
  top: 9px;
  margin-left: 10px;
  min-width: 20px;
  text-align: right;
  flex-shrink: 0;
}
</style>
