<template>

 
  <ClientOnly>
    <Teleport to="#app">
      
      

      <UiModal
        v-if="activeContext"
        :title="''"
        :width="'70%'"
        :is-auto-height="true"
        :max-width="'400px'"
        @close="close"         
        >        
        
        <div class="context_list" v-if="wikiData">

          <div class="ttl">
            {{ wikiData.title }}
          </div>


          <img class="ic" :src="wikiData.icon" :title="wikiData.title">


          <div class="info" v-html="wikiData.info"></div>

          <UiButton
            :name="'Show more'"
            :type="'primary wide'"
            @click="openFull"
            />     


        </div>

      </UiModal>

    </Teleport>    

  </ClientOnly>

</template>

<script setup>
 
const { $api } = useNuxtApp();
 
// const props = defineProps({
  
// })

const activeContext = ref(useWikiModal().active);
const data = ref(useWikiModal().data);
const wikiData = ref(null);


const close = () => {
  useWikiModal().close();
  // activeContext.value = false;
}

const loadContent = async () => {
  if(data.value.type && data.value.id)
  wikiData.value = await $api.getWiki('short', data.value.type, data.value.id);
}

const openFull = () => {
  useWikiModalFull().open(data.value.type, data.value.id);
  close();
  // if(data.value.size && data.value.type && data.value.id)
  // wikiData.value = await $api.getWiki(data.value.size, data.value.type, data.value.id);
}


watch(() => data, (new_data) => {
  console.log('new_data', new_data);
    loadContent();
}, { deep: true })

</script>

<style scoped>
  .ctx{        
    min-width: 59px;
    display: flex;
    justify-content: center;
  }
  .ctx > i{    
    padding: 14px 20px 14px 25px;
    cursor: pointer;
  }

  /*  */

  .context_list{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  } 

  .ttl{
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .info{
    margin-bottom: 1rem;
  }
  .ic{
    max-width: 290px;
    margin-bottom: 1rem;
    width: 90%;
    margin: 1rem auto;
  }
</style>
