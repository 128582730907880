<template>
  <div class="following">

    <!-- {{ useFollowCommon() }} -->
    


    <client-only>
 
 
    <!--  -->    

    <SkNavbarFollowing
      v-if="!common.loaded"
      />

    <!--  -->

    <div class="common" v-if="common.loaded && common.items.length">
      
      <div class="ttl">

        <span class="tnm">{{ $t('main_menu_following_title') }}</span>
 
        <UiSort 
          @choose="chooseCommonSort($event)"
          :val="common.sort"    
          :enablehint="false"
          :enablename="false"
          :options="[
            {id:'update', name: $t('main_menu_following_sort_updates')},      
            {id:'az', name: $t('main_menu_following_sort_alpha')},      
            {id:'follow_date', name: $t('main_menu_following_sort_date')},       
          ]"
          />
          
      </div>

      <div class="lst">
        <template
          v-for="(i, ik) in common.items"          
          >
          <FollowingItem
            :avatar="i.avatar_small"
            :name="i.name"
            :link="i.link"
            :type="i.type"
            :updates="i.cnt_update"
            @click="click(i)"
            />
        </template>
      </div>

      <div :class="{'mr': true, 'gloading': commonLoading}" v-if="common.total > common.items.length" @click="loadCommonMore">
        {{$t('universal_load_more')}} 
        <!-- ({{common.total - common.items.length }}) -->
      </div>

    </div>

    <!--  -->

    <SkNavbarFollowing
      v-if="!bookmark?.loaded"
      />

    <!--  -->

    <div class="bookmark" v-if="bookmark.loaded && bookmark.items.length">
      
      <div class="ttl"> 

        <span class="tnm">{{ $t('main_menu_bookmarks_title') }}</span>
        
        <UiSort 
          @choose="chooseBookmarkSort($event)"
          :val="bookmark.sort"
          :enablehint="false"
          :enablename="false"
          :options="[
            {id:'update', name: $t('main_menu_following_sort_updates')},      
            {id:'az', name: $t('main_menu_following_sort_alpha')},      
            {id:'follow_date', name: $t('main_menu_following_sort_date')},       
          ]"
          />

      </div>

      <div class="lst">
        <template
          v-for="(i, ik) in bookmark.items"          
          >
          <FollowingItem
            :avatar="i.avatar_small"
            :name="i.name"
            :link="i.link"
            :type="i.type"
            :updates="i.cnt_update"
            @click="click(i)"
            />
        </template>
      </div>

      <div :class="{'mr': true, 'gloading': bookmarkLoading}" v-if="bookmark.total > bookmark.items.length" @click="loadBookmarkMore">
        {{$t('universal_load_more')}} 
       
        <!-- ({{ bookmark.total - bookmark.items.length }}) -->
      </div>

    </div>
 
    <div
      v-if="!bookmark.items?.length && !common.items?.length"
      class="empty"
      >

        <span class="ttl">{{ $t('main_menu_following_title') }}</span>

        <i class="icon-flag"></i>

        {{ $t('main_menu_following_empty_placeholder_1') }}
        <NuxtLink to="/seedbank">{{ $t('main_menu_following_empty_placeholder_2') }}</NuxtLink>
        &
        <NuxtLink to="/growers">{{ $t('main_menu_following_empty_placeholder_3') }}</NuxtLink>
        {{ $t('main_menu_following_empty_placeholder_4') }}


        <div class="sivebar_advplace"></div>
        

      </div>


    </client-only>
  </div>
</template>


<script setup>

const { $api } = useNuxtApp();
const emits = defineEmits(['click:link'])

const {followCommon: common, clear: commonClear} = useFollowCommon()
const {followBookmark: bookmark, clear: bookmarkClear} = useFollowBookmark()
const commonLoading = ref(false)
const bookmarkLoading = ref(false)
// const bookmark = useFollowBookmark().followBookmark
// const commonLoad = ref(useFollowCommon().load)
// const bookmarkLoad = ref(useFollowBookmark().load)
// const commonClear = ref(useFollowCommon().clear)
// const bookmarkClear = ref(useFollowBookmark().clear)
 
const commonLoad = async () => {
  commonLoading.value = true;
  let res = await $api.getGrowerFollowingCommon(useAuth().getId(), common.value.start, common.value.limit+1, common.value.sort)      
  common.value.user_id = useAuth().user.value.id
  common.value.loaded = true
  common.value.total = res.items_follow?.length;
  common.value.items = res.items_follow?.length ? res.items_follow.slice(0, common.value.limit) : [];
  common.value.start = common.value.start + common.value.limit
  commonLoading.value = false;
}
 
const bookmarkLoad = async () => {
  bookmarkLoading.value = true;
  let res = await $api.getGrowerFollowingBookmark(useAuth().getId(), bookmark.value.start, bookmark.value.limit+1, bookmark.value.sort)      
  bookmark.value.user_id = useAuth().user.value.id
  bookmark.value.loaded = true
  bookmark.value.total = res.items_follow?.length;
  bookmark.value.items = res.items_follow?.length ? res.items_follow.slice(0, bookmark.value.limit) : [];
  bookmark.value.start = bookmark.value.start + bookmark.value.limit
  bookmarkLoading.value = false;
}

const loadCommonMore = () => {          
  common.value.start = 0;
  common.value.limit = 1000;
  commonLoad();         
}

const loadBookmarkMore = () => {          
  bookmark.value.start = 0;
  bookmark.value.limit = 1000;
  bookmarkLoad();         
}

const chooseCommonSort = (val) => {
  common.value.sort = val;    
  common.value.start = 0; 
  commonLoad();            
}

const chooseBookmarkSort = (val) => {      
  bookmark.value.sort = val;    
  bookmark.value.start = 0;
  bookmark.value.limit = 1000;
  bookmarkLoad();            
}


const click = (item) => {
  emits('click:link');


  console.log('follow item click');
  console.log(item);
  item.cnt_update = 0;
 
    $api.patchFollow(item.type, item.content_id)
    .then(res => {
      
    })
    .catch(err => {
      console.log(err);
    })

}

onMounted( async () => {
  if(import.meta.client){
    if(!common.value.loaded){
      nextTick(() => {
        commonLoad();
        bookmarkLoad();
      })
    }
  }
})

watch(() => useAuth().user.value.id, (val, old_val) => {
  if(import.meta.server){
    return;
  }

  if(common.value.user_id != val && common.value.loaded){
    console.log('watch user id in following - clear');
    commonClear();
    bookmarkClear();
    commonLoad();
    bookmarkLoad();
  }
}, {immediate: true, deep: true})

</script>

  

<style scoped>

.bookmark,
.common{
  border-top: 1px var(--gd-navbar-border-color) solid;
  margin: 0 20px;
  padding-top: 5px;
}

.bookmark{
  margin-top: 1rem;
}
 
.ttl {
  display: flex;
  align-items: center;
  color: var(--un-primary-back-color);
  font-weight: bold;
  padding: 0px 10px;
  margin: 0px 0px;
}
.tnm{ 
  margin-right: auto;
}
.lst{
  display: flex;
  flex-direction: column;
  margin: 0px 0px;
}

.mr{
  padding: 1rem 2rem;
  cursor: pointer;
  position: relative;
}
.mr:hover{
  color: var(--gd-link-hover-color);
}


.empty{
  border-top: 1px var(--gd-navbar-border-color) solid;
  padding-top: 10px;
  margin: 30px 30px;
  margin-top: 0px;
}
</style>
