<template>

  <footer> 
 
    <div>
      <UiToggle 
        @update:modelValue="switchTheme"
        :label="'Dark mode'"
        v-model="theme" 
        />
    </div>
    
    <NuxtLink @click="$emit('click:link')" class="lnk" to="/contact">Contact us</NuxtLink>
    <NuxtLink @click="$emit('click:link')" class="lnk" to="/faq">FAQ</NuxtLink>
    <NuxtLink @click="$emit('click:link')" class="lnk" to="/policy">Privacy</NuxtLink>
    <NuxtLink @click="$emit('click:link')" class="lnk" to="/terms">Terms</NuxtLink>
    <NuxtLink @click="$emit('click:link')" class="lnk" to="/partner">Advertising</NuxtLink>
    <NuxtLink @click="$emit('click:link')" v-if="!useAuth().isAuth()" class="lnk" to="/auth/signin">Login</NuxtLink>
    <NuxtLink @click="$emit('click:link')" v-if="!useAuth().isAuth()" class="lnk" to="/auth/signup">Sign Up</NuxtLink> 

    <!-- <span class="lnk" @click="$store.dispatch('navbar/toggleBlacktheme')">Change theme</span> -->

    <div class="socials">
      <a class="lnk" title="facebook" rel="noreferrer" href="https://www.facebook.com/growdiaries/?fref=ts" target="_blank"><i class="icon-facebook"></i></a>      
      <a class="lnk" title="instagram" rel="noreferrer" href="https://www.instagram.com/grow.diaries/" target="_blank"><i class="icon-instagram"></i></a>
      <a class="lnk" title="pinterest" rel="noreferrer" href="https://www.pinterest.com/growdiaries/growdiariescom-daily-marijuana-pics/" target="_blank"><i class="icon-pinterest"></i></a>        
    </div>


    <br>
    <br>


    <div>
      <!--googleoff: all-->GrοwD sοft s.r.ο., LιndΙeyοva 2822/10, Dejvιce, 160 00 Praha 6, Czech RepυbΙιc<!--googleon: all-->
    </div>





  </footer>

  
</template>


<script setup>

const route = useRoute();
const navbarTheme = useNavbarTheme()
const theme = ref(navbarTheme.value == 'dark' ? 1 : 0)

const switchTheme = (val) => {

  if(val){
    theme.value = val
    navbarTheme.value = 'dark'
  }else{
    theme.value = val
    navbarTheme.value = 'light'
  }
}

</script>
 


<style scoped>
  
footer{
  padding: 30px 10px;
  border-top: 1px var(--gd-navbar-border-color) solid;
  margin-top: 30px;
  margin: 30px 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
} 
 
  
footer .lnk{
  font-weight: bold;
  color: gray;
  font-size: 0.8rem;
  cursor: pointer;
} 
.offnav footer{
   display: none;
}
.socials{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  
}

@media all and (max-width: 768px) {


}

</style>