<template>
  <div class="following">


    <span class="ttl">{{ $t('main_menu_following_title') }}</span>

    <i class="icon-flag"></i>

    {{ $t('main_menu_following_empty_placeholder_1') }}
    <NuxtLink to="/seedbank">{{ $t('main_menu_following_empty_placeholder_2') }}</NuxtLink>
    &
    <NuxtLink to="/growers">{{ $t('main_menu_following_empty_placeholder_3') }}</NuxtLink>
    {{ $t('main_menu_following_empty_placeholder_4') }}


    <div class="sivebar_advplace"></div>


  </div>
</template>


<script setup>

 

</script>

  

<style scoped>

.following{
  border-top: 1px var(--gd-navbar-border-color) solid;
  padding-top: 10px;
  margin: 30px 30px;
  margin-top: 0px;
}
.ttl {
  display: flex;
  align-items: center;
  color: var(--un-primary-back-color);
  font-weight: bold;
  margin-bottom: 1rem;
  /* padding: 9px 10px; */
}

</style>
